<template>
  <div>
    <h3>CSV File Uploader</h3>
    <CsvUploader />
  </div>
</template>

<script>
import CsvUploader from '../../components/CsvUploader.vue';

export default {
  components: {
    CsvUploader,
  },
};
</script>
