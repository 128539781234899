<template>
  <div>
    <input
      class="mb-2 btn btn-default btn-sm"
      type="file"
      accept=".csv"
      @change="handleFileUpload"
    />
    <button
      class="mb-2 btn btn-default btn-sm"
      @click="saveData"
      :disabled="!uploadedData"
    >
      Save to Firestore
      <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
    </button>

    <div v-if="uploadedData">
      <h2>Uploaded Data Preview</h2>
      <table class="table">
        <thead>
          <tr>
            <th>Amount</th>
            <th>Date Inserted</th>
            <th>Flow</th>
            <th>Product Ref ID</th>
            <th>Reason</th>
            <th>Source</th>
            <th>User ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in uploadedData" :key="entry.product_ref_id">
            <td>{{ entry.amount }}</td>
            <td>{{ entry.date_inserted }}</td>
            <td>{{ entry.flow }}</td>
            <td>{{ entry.product_ref_id }}</td>
            <td>{{ entry.reason }}</td>
            <td>{{ entry.source }}</td>
            <td>{{ entry.user_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
  
  <script>
import "firebase/firestore";
import csvtojson from "csvtojson";
import { db } from "../firebase";
import { alert } from "../utils/alertUtils";
import { collection, addDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

export default {
  data() {
    return {
      uploadedData: null,
      firestoreCollection: "cashflow",
      show_loader: false,
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = () => {
        const fileContent = reader.result;
        this.parseCSV(fileContent);
      };

      reader.readAsText(file);
    },
    parseCSV(fileContent) {
      csvtojson()
        .fromString(fileContent)
        .then((data) => {
          this.uploadedData = data;
        });
    },
    saveData() {
  this.show_loader = true;

  const batch = [];

  this.uploadedData.forEach((entry) => {
    // Convert the string date_inserted to a Firestore Timestamp
    const timestamp = Timestamp.fromDate(new Date(entry.date_inserted));

    // Remove the original date_inserted property and add the timestamp property
    const updatedEntry = { ...entry, date_inserted: timestamp };

    batch.push(addDoc(collection(db, this.firestoreCollection), updatedEntry));
  });

  Promise.all(batch)
    .then(() => {
      this.show_loader = false;
      alert.success_center("You have uploaded data successfully.");
    })
    .catch((e) => {
      this.show_loader = false;
      alert.error("An error occurred. Please try again.");
      console.log(e);
    });
},
  },
};
</script>
  